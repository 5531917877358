
import {map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs'
import { TarefasPendentes } from '../../model/tarefas-pendentes';
import { ListaProcessosTarefa } from '../../model/lista-processos-tarefa';
import { PROCESSOS_TAREFA } from '../../mocks/mock-processos-tarefa';
import { PJE_LEGACY } from './rest-servers';
import { GenericService,  } from './generic.service';
import { AppConfig } from '../../app.config';
import { Transicao } from '../../model/transicao';
import { Variavel } from 'app/model/variavel.model';
import { PjeResponse } from 'app/model';

@Injectable()
export class TarefaService extends GenericService<TarefasPendentes>{
    private urlPrefix: String = PJE_LEGACY + '/painelUsuario';

    constructor(private http: HttpClient, private config:AppConfig) { 
        super(http, config);
    }

    public setUrlPrefix(urlPrefix : string) {
        this.urlPrefix = urlPrefix;
    }    

    getTarefasPendentes(criterios? : any): Observable<TarefasPendentes[]>{
        let params : any = {};
        if(criterios) {
            params = criterios;
        } else {
            params = {numeroProcesso: '', competencia: '', etiquetas: []};
        }
        return this.http.post<TarefasPendentes[]>(this.getLegacyUrl(this.urlPrefix + "/tarefas"), params);
    }
    
    getTarefasFavoritas(criterios? : any): Observable<TarefasPendentes[]>{
        let params : any = {};
        if(criterios) {
            params = criterios;
        } else {
            params = {numeroProcesso: '', competencia: '', etiquetas: []};
        }
        return this.http.post<TarefasPendentes[]>(this.getLegacyUrl(this.urlPrefix + "/tarefasFavoritas"), params);        
    }

    getTarefasAssinatura(): Observable<any[]>{
        return this.http.get<any>(this.getLegacyUrl(this.urlPrefix + "/tarefas/minutas"));
    }

    getProcessosTarefaAssinatura(tipoDocumento: string, params: any): Observable<ListaProcessosTarefa>{
        return this.http.post<ListaProcessosTarefa>(
                this.getLegacyUrl(this.urlPrefix + '/recuperarProcessosTarefaAssinaturaComCriterios' + '/' + encodeURI(tipoDocumento)), 
                params
            ).pipe(catchError(this._serverError));        
    }

    getTarefasDaLocalizacao(): Observable<any[]>{
        return this.http.get<any>(this.getLegacyUrl(this.urlPrefix + "/tarefas"));
    }

    getProcessosTarefa(nomeTarefa:string, params:any, somenteFavoritas: boolean): Observable<ListaProcessosTarefa>{
        return this.http.post<ListaProcessosTarefa>(
            this.getLegacyUrl(this.urlPrefix + '/recuperarProcessosTarefaPendenteComCriterios' + '/' + encodeURI(nomeTarefa) + '/' + somenteFavoritas), 
            params
            ).pipe(catchError(this._serverError));
    }

    getProcessoByTarefaIdentificador(nomeTarefa:string, idProcesso: number){
        return this.http.get( 
            this.getLegacyUrl(this.urlPrefix + '/recuperarProcessoPorTarefaIdentificador/' + nomeTarefa + '/' + idProcesso));
    }    

    getTarefaPendenteByIdTaskInstance(idTaskInstance: number ){
        return this.http.get( 
            this.getLegacyUrl(this.urlPrefix + '/recuperarProcesso/'+ idTaskInstance +'/false'));
    }

    getTrasicoesSaidaTarefa(idTarefa: number): Observable<any>{
        return this.http.get(
                this.getLegacyUrl(this.urlPrefix + '/transicoes/' + idTarefa)
            );
    }

    getProcessosTarefaMock() : ListaProcessosTarefa{
        return PROCESSOS_TAREFA;
    }

    conferirTarefaProcesso(idTaskInstance: number, idProcesso: number) : Observable<boolean>{
        return this.http.post(
                this.getLegacyUrl(this.urlPrefix + '/conferenciaProcesso' + "/" + idTaskInstance + "/" + idProcesso), {}).pipe(
                map(
                    (res: Response) => {
                        return true;
                    },
                    (err: Response) => {
                        return false;
                    }
                ));
    }

    desconferirTarefaProcesso(idTaskInstance: number, idProcesso: number) : Observable<boolean>{
        return this.http.delete(
                this.getLegacyUrl(this.urlPrefix + '/conferenciaProcesso' + "/" + idTaskInstance + "/" + idProcesso)).pipe(
                map(
                    (res: Response) => {
                        return true;
                    },
                    (err: Response) => {
                        return false;
                    }
                ));
    }

    limparResponsavelTarefa(idTaskInstance: number): Observable<boolean> {
        return this.http.get(this.getLegacyUrl(this.urlPrefix + '/tarefa/limparResponsavel/'+idTaskInstance)).pipe(
            map(
                (response: Response) => {return true;},
                (err:Response) => {return false;} 
            ));
    }

    movimentarTarefa(idTaskInstance, nomeTransicao): Observable<boolean>{
        return this.http.get(this.getLegacyUrl(this.urlPrefix + '/movimentar/' + idTaskInstance + '/' + encodeURI(nomeTransicao))).pipe(
                map(
                    (response) => {
                        return true;
                    },
                    (err) => {
                        return false;
                    }
                ));
    }

    recuperarQuantitativoAssinaturasPorEtiqueta(tipoDocumento: string, params: any): Observable<any>{
        return this.http.post(
                this.getLegacyUrl(this.urlPrefix + "/recuperarEtiquetasQuantitativoProcessoAssinaturaPendente" + "/" + encodeURI(tipoDocumento)), params);
    }

    recuperarQuantitativoTarefasPorEtiqueta(tarefa: string, somenteFavoritas: boolean, params: any): Observable<any>{
        return this.http.post(
                this.getLegacyUrl(this.urlPrefix + "/recuperarEtiquetasQuantitativoProcessoTarefaPendente" + "/" + encodeURI(tarefa) + "/" + somenteFavoritas), params);
    }

    recuperarProcessosTarefaPorEtiqueta(tarefa: string, idTag: number, somenteFavoritas: boolean, params: any){
        return this.http.post(
                this.getLegacyUrl(this.urlPrefix + "/recuperarProcessosTarefaPendentePorEtiqueta" + "/" + encodeURI(tarefa) + "/" + idTag + "/" + somenteFavoritas), params);
    }

    recuperarProcessosAssinaturaPorEtiqueta(tipoDocumento: string, idTag: number, params: any){
        return this.http.post(
                this.getLegacyUrl(this.urlPrefix + "/recuperarProcessosTarefaAssinaturaPendentePorEtiqueta/" + tipoDocumento + "/" + idTag ), params);
    }  
    
    getBreadcrumTarefa(idTaskInstance: number, idProcesso : number): Observable<any>{
        return this.http.get(this.getLegacyUrl(this.urlPrefix + '/breadcrumb/' + idTaskInstance + "/" + idProcesso));
    }
    
    getHistoricoTarefas(idProcesso : number) : Observable<any> {
        return this.http.get(this.getLegacyUrl(this.urlPrefix + "/historicoTarefas/" + idProcesso));
    }

    assinarTarefa(tarefa: any): Observable<any>{
        return this.http.post<any>(
                this.getLegacyUrl(this.urlPrefix + '/assinarTarefa'), 
                tarefa.arquivos[0]);
            
    }

    getResponsavelProcesso(idTarefa: number): Observable<any>{
        return this.http.get(
                this.getLegacyUrl(this.urlPrefix + '/processo/buscarResponsavel/' + idTarefa)
            );
    }

    movimentarTarefaIndividual(idTaskInstance: string, nomeTransicao: string): Observable<Transicao> {
        return this.http.get<Transicao>(this.getLegacyUrl(this.urlPrefix + '/movimentarIndividual/' + idTaskInstance + '/' + nomeTransicao));
    }

    recuperarVariavel(idProcessInstance: number, nomeVariavel: string): Observable<PjeResponse<Variavel>>{
        return this.http.get<PjeResponse<Variavel>>(
            this.getLegacyUrl(
                PJE_LEGACY + '/api/v1/process-instances/' + idProcessInstance + '/variables/' + nomeVariavel));
    }

    gravarVariavel(idProcessInstance: number, variavel: Variavel): Observable<PjeResponse<Variavel>>{
        return this.http.post<PjeResponse<Variavel>>(
            this.getLegacyUrl(
                PJE_LEGACY + '/api/v1/process-instances/' + idProcessInstance + '/variables'), variavel);
    }

    recuperarVariavelTarefa(idTaskInstance: number, nomeVariavel: string): Observable<PjeResponse<Variavel>>{
        return this.http.get<PjeResponse<Variavel>>(
            this.getLegacyUrl(
                PJE_LEGACY + '/api/v1/task-instances/' + idTaskInstance + '/variables/' + nomeVariavel));
    }

    gravarVariavelTarefa(idTaskInstance: number, variavel: Variavel): Observable<PjeResponse<Variavel>>{
        return this.http.post<PjeResponse<Variavel>>(
            this.getLegacyUrl(
                PJE_LEGACY + '/api/v1/task-instances/' + idTaskInstance + '/variables'), variavel);
    }
}