import { Injectable } from '@angular/core';
import { AppConfig } from '../../app.config';

@Injectable()
export class UtilService {

    constructor( private config: AppConfig ) { }

    url(...content: any[]): string{

        var urlFinal = content[0];
        
        if (content.length > 1){
            urlFinal = urlFinal.concat("?");
        }
        
        for (var i=1, t = content.length; i < t; i++) {
            
            urlFinal = urlFinal.concat(content[i]);
            
            if ((i +1) % 2 === 0) {
                urlFinal = urlFinal.concat("=");
            }
            else if (i+1  < t) {
                urlFinal = urlFinal.concat("&");
            }			
        }
        return urlFinal;        
    }

    webRoot(): string {
        return this.config.getUrlPjelegacy();
    }

    pjeLegacyContextPath(): string {
        return this.config.getContextPathPjeLegacy();
    }

    codigoSeguranca(): string {
        return this.config.pjeCodigoSeguranca;
    }

    mobile(): string {
        return this.config.pjeMobile;
    }

    tipoEditor(): string {
        return this.config.tipoEditor;
    }

    primeiraInstancia(): boolean {
        return this.config.getInstancia() === '1';
    }

    urlMarketplace(): string {
        return this.config.getUrlPDPJMarketplace();
    }

    srvSemPath(url: string) {
		return '/seam/resource/rest' + url;
	};

    jsessionid():string{
        return this.getCookie("JSESSIONID");
    }

    private getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(window.document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
}