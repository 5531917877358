import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { ForbiddenPageComponent } from './forbidden-page/forbidden-page.component';

const routes: Routes = [
	{ path: 'painel-usuario-interno', loadChildren: () => import('app/painel/painel-usuario-interno/painel-usuario-interno.module').then(m => m.PainelUsuarioInternoModule) },
	{ path: 'criminal', loadChildren: () => import('app/criminal/criminal.module').then(m => m.CriminalModule) },
	{ path: 'cadastro-processo', loadChildren: () => import('app/cadastro-processo/cadastro-processo.module').then(m => m.CadastroProcessoModule)},
	{ path: 'status', loadChildren: () => import('app/status/status.module').then(m => m.StatusModule)},
	{ path: 'competencia', loadChildren: () => import('app/competencia/competencia.module').then(m => m.CompetenciaModule)},
	{ path: 'bacen', loadChildren: () => import('app/bacen/bacen.module').then(m => m.BacenModule)},
	{ path: 'ecarta', loadChildren: () => import('app/ecarta/ecarta.module').then(m => m.ECartaModule)},
	{ path: 'requisitorio', loadChildren: () => import('app/requisitorio/requisitorio.module').then(m => m.RequisitorioModule)},
	{ path: 'inss', loadChildren: () => import('app/inss/inss.module').then(m => m.InssModule)},
	{ path: 'mni-client', loadChildren: () => import('app/mni-client/mni-client.module').then(m => m.MniClientModule)},
	{ path: 'renajud', loadChildren: () => import('app/renajud/renajud.module').then(m => m.RenajudModule)},
	{ path: 'leilao', loadChildren: () => import('app/leilao/leilao.module').then(m => m.LeilaoModule)},
	{ path: 'forbidden-page', component: ForbiddenPageComponent }
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true })],
	exports: [RouterModule],
})
export class PJeRoutingModule { }

export const routedComponents = [];