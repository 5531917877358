
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { AppConfig } from '../../app.config';

@Injectable()
export class GenericService<T> {

    private restPath: string = "";

    constructor(private _http: HttpClient, private _config: AppConfig) { 
    }

    _serverError(err: any) {
        console.error('sever error:', err);
        return observableThrowError(err || 'backend server error');
    }

    findAll(): Observable<T[]>{
        return this
            ._http
            .get<T[]>(this.getLegacyUrl(this.restPath)).pipe(
            catchError(this._serverError));    
    }

    findById(id: number): Observable<T>{
        return this
            ._http
            .post<T>(this.getLegacyUrl(this.restPath) + '/' + id, {}).pipe(
            catchError(this._serverError));
    }

    getLegacyUrl(nomeServico:string): string{
        let url: string = "";
        
        if(!nomeServico){
            nomeServico = "";
        } else {
            if(this._config.corsEnabled){
                url = this._config.getUrlPjelegacy() + nomeServico;
            } else {
                url = this._config.getUrlApiGateway() + "/" +this._config.pjeLegacyCloudAppName + this._config.getContextPathPjeLegacy() + nomeServico;
            }
        } 

        return url;
    }
    
    public get getRestPath() : string {
        return this._config.getUrlApiGateway();
    }

    
    public set setRestPath(restPath : string) {
        this.restPath = restPath;
    }
    
    
}