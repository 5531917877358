import {map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs'
import { GenericService } from './generic.service';
import { AppConfig } from '../../app.config';
import { PJE_LEGACY } from './rest-servers';

@Injectable()
export class PericiaService extends GenericService<any> {
    private urlPrefix: String = PJE_LEGACY + '/modalPericiaLote';

    constructor(private http: HttpClient, private config:AppConfig) {
        super(http, config);
    }

    recuperarEspecialidades(): Observable<any> {
        return this.http.get(this.getLegacyUrl(this.urlPrefix + '/especialidades'));
    }

    recuperarPeritos(idPerito: number, idsOJ: number[]): Observable<any> {
        let httpParams = new HttpParams();
        idsOJ.forEach((id) => {
            httpParams = httpParams.append('idsOJ', id.toString());
        });
        return this.http.get(this.getLegacyUrl(this.urlPrefix + '/peritos/' + idPerito), {params: httpParams});
    }

    designar(idTaskInstance: number, idProcesso: number,
        idEspecialidade: number, idPerito: number, valor: number, dataInicio: Date): Observable<any> {

        let params = new HttpParams();
        if (idEspecialidade != null) {
            params = params.set('idEspecialidade', idEspecialidade.toString());
        }
        if (idPerito != null) {
            params = params.set('idPerito', idPerito.toString());
        }
        if (valor != null) {
            params = params.set('valor', valor.toString());
        }
        if (dataInicio != null) {
            params = params.set('dataInicio', dataInicio.toString());
        }

        return this.http.get(this.getLegacyUrl(this.urlPrefix + '/designar/' + idTaskInstance + '/' + idProcesso), {params: params})
                    .pipe(catchError(this._serverError));
    }

}