import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UtilService } from '../../core/services';
import { KeycloakService } from 'keycloak-angular';
import { AppConfig } from 'app/app.config';

@Injectable()
export class PjeHttpClientInterceptor implements HttpInterceptor {
    constructor(private config: AppConfig, protected keycloakAngular: KeycloakService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
        let user : any = JSON.parse(sessionStorage.getItem('currentUser'));

        let headers: HttpHeaders;

        if(!user){
                user = {};
                user.login = "X";
        }

        if(this.keycloakAngular.getKeycloakInstance() && this.keycloakAngular.getKeycloakInstance().token) {
            headers = req.headers
                .set('Content-Type', 'application/json')
                .set("X-pje-legacy-app", this.config.pjeLegacyCloudAppName)
                .set("X-pje-cookies", this.config.cookies)
                .set("X-pje-usuario-localizacao", this.config.idUsuarioLocalizacao);

            if (!req.headers.has("Authorization")) {
                headers = headers.append("Authorization", "Bearer " + this.keycloakAngular.getKeycloakInstance().token);
            }
        } else {
            headers = req.headers
                .set('Content-Type', 'application/json')
                .set("X-no-sso", "true")
                .set("X-pje-legacy-app", this.config.pjeLegacyCloudAppName)
                .set("X-pje-cookies", this.config.cookies)
                .set("X-pje-authorization", "Basic " + btoa(user.login + ':12345'));

            if (!req.headers.has("Authorization")) {
                headers = headers.append("Authorization", "Basic " + btoa(user.login + ':12345'));
            }
                
        }
        
        const withCredentials = true;
        const authReq = req.clone({ headers, withCredentials });
        
        return next.handle(authReq);
    }
}