
import {catchError,  map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { GenericService } from './generic.service';
import { Observable } from 'rxjs'
import { PJE_LEGACY } from './rest-servers';
import { AppConfig } from '../../app.config';

@Injectable()
export class SessaoService extends GenericService<any>{

    private urlPrefix: String = PJE_LEGACY + '/painelUsuario';

    constructor(private http: HttpClient, private config:AppConfig) { 
        super(http, config);
    } 

    getTiposSessao(): Observable<any[]>{
        return this.http.get<any[]>(this.getLegacyUrl(this.urlPrefix + '/tiposSessao'));
    }

    getSessoes(params:any): Observable<any>{
        return this.http.post(
                this.getLegacyUrl(this.urlPrefix + '/sessoesComCriterios')
                , params
            ).pipe(catchError(this._serverError));
    }    
}