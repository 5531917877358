import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GenericService} from '.';
import {AppConfig} from '../../app.config';
import {PJE_LEGACY} from './rest-servers';
import {CabecalhoSistema} from "../../model/cabecalho-sistema";
import {catchError} from "rxjs/operators";
import {Observable} from "rxjs";
import { PjeResponse } from 'app/model';

@Injectable()
export class ParametrosService extends GenericService<any> {

  private srvPath: String = PJE_LEGACY + '/parametros';
  private srvApiPath: String = PJE_LEGACY + '/api/v1';

  constructor(private http: HttpClient, private config: AppConfig) {
    super(http, config);
  }

  recuperaCabecalhoSistema(): Observable<CabecalhoSistema> {
    return this.http.get<CabecalhoSistema>(this.getLegacyUrl(this.srvPath + '/cabecalhoSistema')).pipe(catchError(this._serverError));
  }

  findyByName(name:string): Observable<any>{
    return this.http.get<any>(this.getLegacyUrl(this.srvApiPath + '/parametros/' + name)).pipe(catchError(this._serverError));
  }

}
