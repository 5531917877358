import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs'
import { GenericService } from '.';
import { AppConfig } from '../../app.config';
import { PJE_LEGACY } from './rest-servers';
import { CabecalhoProcesso, PjeResponse } from '../../model';
import { ProcessoParte } from "../../criminal/model";
import { Movimento } from '../../model/movimento';
import { DocumentoJudicial } from 'app/inss/models/models';

@Injectable()
export class ProcessoJudicialService extends GenericService<any>{
    private srvPath: String = PJE_LEGACY + '/processos';

    constructor(private http: HttpClient, private config: AppConfig) {
        super(http, config);
    }

    getCabecalhoProcesso(idProcesso: number): Observable<CabecalhoProcesso> {
        return this.http.get<CabecalhoProcesso>(this.getLegacyUrl(this.srvPath + '/' + idProcesso)).pipe(
            catchError(this._serverError));
    }

    getPartesPoloPassivo(idProcesso: number): Observable<ProcessoParte[]> {
        return this.http.get<ProcessoParte[]>(this.getLegacyUrl(this.srvPath + '/' + idProcesso + '/poloPassivo')).pipe(
            catchError(this._serverError));
    }

    getPartesPoloAtivo(idProcesso : number) : Observable<ProcessoParte[]>{
      return this.http.get<ProcessoParte[]>(this.getLegacyUrl(this.srvPath + '/' + idProcesso + '/poloAtivo')).pipe(
          catchError(this._serverError));
    }

    getAssuntos(idProcesso : number) : Observable<any[]>{
        return this.http.get<any[]>(this.getLegacyUrl(this.srvPath + '/' + idProcesso + '/assuntos')).pipe(
            catchError(this._serverError));
    }

    getStatusProcesso(idProcesso: number): Observable<any> {
        return this.http.get<ProcessoParte>(this.getLegacyUrl(this.srvPath + '/' + idProcesso + '/status')).pipe(
            catchError(this._serverError));
    }

    getMovimentos(idProcesso: number): Observable<any> {
        return this.http.get<ProcessoParte>(this.getLegacyUrl(this.srvPath + '/' + idProcesso + '/movimentacoes')).pipe(
            catchError(this._serverError));
    }

    getUltimoMovimento(idProcesso: number): Observable<Movimento> {
        return this.http.get<Movimento>(this.getLegacyUrl(this.srvPath + '/' + idProcesso + '/ultimoMovimento'))
            .pipe(catchError(this._serverError));
    }

    getTarefas(idProcesso: number): Observable<any> {
        return this.http.get<any>(this.getLegacyUrl(this.srvPath + '/' + idProcesso + '/tarefas'))
            .pipe(catchError(this._serverError));
    }

    getDocumentosJudiciais(idProcesso: number): Observable<any> {
        return this.http.get<any>(this.getLegacyUrl(this.srvPath + '/' + idProcesso + '/documentosJudiciais'))
            .pipe(catchError(this._serverError));
    }

    getCompetencias(): Observable<any[]> {
        return this.http.get<any[]>(this.getLegacyUrl(this.srvPath + '/competencias'))
            .pipe(catchError(this._serverError));
    }

    getJurisdicoesByCompetencia(idCompetencia: number): Observable<any[]> {
        return this.http.get<any[]>(this.getLegacyUrl(this.srvPath + '/jurisdicoes/competencia/' + idCompetencia))
            .pipe(catchError(this._serverError));
    }

    getClasses(idCompetencia: number): Observable<any[]> {
        return this.http.get<any[]>(this.getLegacyUrl(this.srvPath + '/classesJudiciais/' + idCompetencia))
            .pipe(catchError(this._serverError));
    }

    getAssuntosByCompetenciaAndClasse(idCompetencia: number, idClasseJudicial: number): Observable<any[]> {
        return this.http.get<any[]>(this.getLegacyUrl(this.srvPath + '/assuntos/competencia/' + idCompetencia + '/classe/' + idClasseJudicial))
            .pipe(catchError(this._serverError));
    }

    getClasseJudicial(idProcesso: number): Observable<Movimento> {
        return this.http.get<any>(this.getLegacyUrl(this.srvPath + '/' + idProcesso + '/classeJudicial'))
            .pipe(catchError(this._serverError));

    }

    podeManipularInformacaoCriminal(idProcesso: number): Observable<boolean> {
        return this.http.get<any>(this.getLegacyUrl(this.srvPath + '/' + idProcesso + '/pode-manipular-informacao-criminal'))
            .pipe(catchError(this._serverError));
    }

    recuperarDocumentoJudicial(idProcesso: number, idDocumento: number): Observable<PjeResponse<DocumentoJudicial>> {
        return this.http
            .get<PjeResponse<DocumentoJudicial>>(
                this.getLegacyUrl(
                    PJE_LEGACY + '/api/v1/processos-judiciais/' + idProcesso + '/documentos/' + idDocumento))
                .pipe(catchError(this._serverError));
    }

    recuperarPoloAtivo(idProcesso: number): Observable<PjeResponse<any>> {
        return this.http
            .get<PjeResponse<DocumentoJudicial>>(
                this.getLegacyUrl(
                    PJE_LEGACY + '/api/v1/processos-judiciais/' + idProcesso + '/polos/ativos'))
                .pipe(catchError(this._serverError));
    }

}
