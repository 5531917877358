export const PJE_LEGACY = "/seam/resource/rest/pje-legacy";
export const CRIMINAL = "/criminal";
export const COMPETENCIA = "/competencias";
export const BACEN = "/bacen-service";
export const ECARTA = "/ecarta-service";
export const INSSJUD = "/inss-service/api/v1";
export const MNI_CLIENT = "/mni-client";
export const RENAJUD = "/renajud-service"
export const REQUISITORIO = "/requisitorio/api";
export const LEILAO = "/leilao-service";
