import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'pje-tab',
	templateUrl: './pje-tab.component.html',
	styleUrls: ['./pje-tab.component.scss']
})
export class PjeTabComponent implements OnInit {

	@Input('tabTitle') title: string;
	@Input('id') id: string;
	@Input() active = false;

	constructor() { }

	ngOnInit() {
	}


}
