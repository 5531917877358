import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';

@Injectable()
export class NotificationMessageService {

    public defaultOptions = {
        position: ["bottom", "right"],
        timeOut: 5000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true,
        preventDuplicates: true,
        animate: "fromLeft"
    };

    constructor(private notificationsService: NotificationsService) { }

    sendSuccess(title: string, content: string, options?: any){
        if (!options) {
            options = this.defaultOptions;
        }
        this.notificationsService.success(title, content, options);
    }

    sendAlert(title: string, content: string, options?: any){
        if (!options) {
            options = this.defaultOptions;
        }
        this.notificationsService.alert(title, content, options);
    }    

    sendInfo(title: string, content: string, options?: any){
        if (!options) {
            options = this.defaultOptions;
        }
        this.notificationsService.info(title, content, options);
    }    

    sendError(title: string, content: string, options?: any){
        if (!options) {
            options = this.defaultOptions;
        }
        this.notificationsService.error(title, content, options);
    }      

    sendWarn(title: string, content: string, options?: any){
        if (!options) {
            options = this.defaultOptions;
        }
        this.notificationsService.warn(title, content, options);
    }  

}