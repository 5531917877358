
import {catchError,  map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs'
import { Processo } from '../../model/processo';
import { GenericService } from './generic.service';
import { AppConfig } from '../../app.config';
import { PJE_LEGACY } from './rest-servers';

@Injectable()
export class ProcessoService extends GenericService<any>{
    private urlPrefix: String = PJE_LEGACY + '/painelUsuario';

    constructor(private http: HttpClient, private config:AppConfig) { 
        super(http, config);
    }

    getProcessos(crit: string): Observable<Processo[]> {
        return this.http.get<Processo[]>(this.getLegacyUrl(this.urlPrefix + "/processos/" + crit)).pipe(
            catchError(this._serverError));
    }

    carregarChaveProcesso(idProcesso: number){
        return this.http.get(this.getLegacyUrl(this.urlPrefix + "/gerarChaveAcessoProcesso/" + idProcesso), {responseType: 'text'});
    }

    inativarLembrete(idLembrete : number) {
        return this.http.delete(this.getLegacyUrl(this.urlPrefix + "/lembretes/" + idLembrete));
    }
}

