import {catchError, map} from 'rxjs/operators';
import { SentencaExtincaoPunibilidade } from './../../criminal/model/sentenca-extincao-punibilidade.model';
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable, of } from 'rxjs'
import { GenericService } from './generic.service';
import { AppConfig } from '../../app.config';
import { CRIMINAL } from './rest-servers';
import { TipoPrisao, MotivoPrisao, Prisao, Soltura, Fuga } from '../../criminal/model';
import { MotivoSoltura } from '../../criminal/model/motivo-soltura.model';
import { TipoInformacaoCriminal } from '../../criminal/model/tipo-Informacao-criminal.model';
import { PendenciaCriminal, TipoEventoCriminal } from "../../criminal/model/pendencia-criminal";
import { EventoCriminal } from '../../criminal/model/evento-criminal.model';
import { Processo } from "../../model";
import { Estado } from "../../criminal/model/estado.model";
import { SuspensaoProcesso } from "../../criminal/model/suspensao-processo.model";
import { MotivoSuspensaoProcesso } from "../../criminal/model/motivo-suspensao-processo.model";
import { TipoRecurso } from "../../criminal/model/tipo-recurso.model";
import { DecisaoInstanciaSuperior } from "../../criminal/model/decisao-instancia-superior.model";
import { Regime } from '../../criminal/model/regime.model';
import { SentencaCondenatoria } from 'app/criminal/model/sentenca-condenatoria.model';
import { SentencaAbsolutoria } from 'app/criminal/model/sentenca-absolutoria.model';
import { Norma } from 'app/criminal/model/norma.model';
import { SentencaImpronuncia } from 'app/criminal/model/sentenca-impronuncia.model';
import { SentencaPronuncia } from 'app/criminal/model/sentenca-pronuncia.model';
import { DesclassificacaoProcesso } from 'app/criminal/model/desclassificacao-processo.model';
import { Citacao } from 'app/criminal/model/citacao.model';
import { RecebimentoAditamentoDenuncia } from 'app/criminal/model/recebimento-aditamento-denuncia.model';
import { EventoCriminalDTO } from 'app/criminal/model/evento-criminal-dto.model';

@Injectable()
export class CriminalService  extends GenericService<any>{

    constructor(private httpClient: HttpClient, private config:AppConfig) {
        super(httpClient, config);
    }
  
    getEstados() : Observable<Estado[]> {
        return this.httpClient.get<Estado[]>(this.config.getUrlApiGateway() + '/' + CRIMINAL + "/estados").pipe(catchError(this._serverError));
    }

    getNormas(): Observable<Norma[]> {
        return this.httpClient.get<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + "/normas")
            .pipe(
                map((_normas: Norma[]) => {
                    const normas: Norma[] = [];
                    _normas.forEach((n) => normas.push(new Norma(n.id, n.numeroNorma, n.norma,
                        n.sigla, n.inicioVigencia, n.fimVigencia)));
                    return normas;
                }),
                catchError(this._serverError));
    }

    getTiposDispositivos() : Observable<any>{
        return this.httpClient.get<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + "/tiposDispositivos").pipe(catchError(this._serverError));
    }

    getTiposDispositivoArtigo() : Observable<any>{
        return this.httpClient.get<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + "/tiposDispositivos/artigo" ).pipe(catchError(this._serverError));
    }

    getDispositivos(idNorma : number, idTipoDispositivo : number) : Observable<any>{
        return this.httpClient.get<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + "/dispositivos/" + idNorma + "/" + idTipoDispositivo).pipe(catchError(this._serverError));
    }

    getDispositivosByIdDispositivoPai(idDispositivoPai : number) : Observable<any>{
        return this.httpClient.get<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + "/dispositivos/dispositivoPai/" + idDispositivoPai).pipe(catchError(this._serverError));
    }

    getDispositivosByTexto(idNorma : number, idTipoDispositivo : number, texto : string) : Observable<any>{
        return this.httpClient.get<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + "/dispositivos/" + idNorma + "/" + idTipoDispositivo + "/" + texto).pipe(catchError(this._serverError));
    }

    getTiposPrisao() : Observable<TipoPrisao[]>{
        return this.httpClient.get<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + "/tiposPrisao").pipe(catchError(this._serverError));
    }

    getMotivosPrisao() : Observable<MotivoPrisao[]>{
        return this.httpClient.get<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + "/motivosPrisao").pipe(catchError(this._serverError));
    }

    getUnidadesPrisionais(): Observable<any>{
        return this.httpClient.get<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/unidadesPrisionais').pipe(catchError(this._serverError));
    }

    getUnidadesPrisionaisPorSiglaUf(siglaUf : string): Observable<any>{
        return this.httpClient.get<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/unidadesPrisionais/estados/' + siglaUf).pipe(catchError(this._serverError));
    }

    getMotivosSoltura() : Observable<MotivoSoltura[]>{
        return this.httpClient.get<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + "/motivosSoltura").pipe(catchError(this._serverError));
    }

    getIncidenciasPenaisParte(idProcessoCriminal : number, idProcessoParteLegacy : number) : Observable<any[]>{
        return this.httpClient.get<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/incidencias-penais/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy).pipe(catchError(this._serverError));
    }

    postIncidenciaPenalParte(idProcessoCriminal : number, idProcessoParteLegacy : number, dispositvo : any) : Observable<any[]>{
        return this.httpClient.post<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/incidencias-penais/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy, dispositvo).pipe(catchError(this._serverError));
    }

    deleteIncidenciaPenalParte(idProcessoCriminal : number, idProcessoParteLegacy : number, incidenciaPenal : any) : Observable<any>{
        return this.httpClient.post(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/incidencias-penais/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy + '/delete', incidenciaPenal);
    }

    getPrisoesParte(idProcessoCriminal : number, idProcessoParteLegacy : number) : Observable<Prisao[]>{
        return this.httpClient.get<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/prisoes/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy).pipe(catchError(this._serverError));
    }

    postPrisaoParte(idProcessoCriminal : number, idProcessoParteLegacy : number, prisao : Prisao) : Observable<Prisao[]>{
        return this.httpClient.post<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/prisoes/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy, prisao).pipe(catchError(this._serverError));
    }

    postSuspensaoProcessoParte(idProcessoCriminal : number, idProcessoParteLegacy : number, suspensaoProcesso : SuspensaoProcesso) : Observable<SuspensaoProcesso[]>{
        return this.httpClient.post<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/suspensoesProcesso/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy, suspensaoProcesso).pipe(catchError(this._serverError));
    }

    postSentencaCondenatoria(idProcessoCriminal : number, idProcessoParteLegacy : number, sentencaCondenatoria : SentencaCondenatoria) : Observable<SentencaCondenatoria[]>{
        return this.httpClient.post<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/sentencasCondenatorias/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy, sentencaCondenatoria).pipe(catchError(this._serverError));
    }

    alterarPrisaoParte(idProcessoCriminal : number, idProcessoParteLegacy : number, prisao : Prisao) : Observable<Prisao[]>{
        return this.httpClient.put<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/prisoes/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy, prisao).pipe(catchError(this._serverError));
    }

    alterarSuspensaoProcessoParte(idProcessoCriminal : number, idProcessoParteLegacy : number, suspensaoProcesso : SuspensaoProcesso) : Observable<SuspensaoProcesso[]> {
        return this.httpClient.put<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/suspensoesProcesso/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy, suspensaoProcesso).pipe(catchError(this._serverError));
    }

    alterarSentencaCondenatoria(idProcessoCriminal : number, idProcessoParteLegacy : number, sentencaCondenatoria : SentencaCondenatoria) : Observable<SentencaCondenatoria[]> {
        return this.httpClient.put<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/sentencasCondenatorias/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy, sentencaCondenatoria).pipe(catchError(this._serverError));
    }

    deletePrisaoParte(idProcessoCriminal : number, idProcessoParteLegacy : number, prisao : Prisao) : Observable<any>{
        return this.httpClient.post<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/prisoes/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy + '/delete', prisao);
    }

    getFugasParte(idProcessoCriminal : number, idProcessoParteLegacy : number) : Observable<Fuga[]>{
        return this.httpClient.get<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/fugas/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy).pipe(catchError(this._serverError));
    }

    postFugaParte(idProcessoCriminal : number, idProcessoParteLegacy : number, fuga : Fuga) : Observable<Fuga[]>{
        return this.httpClient.post<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/fugas/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy, fuga).pipe(catchError(this._serverError));
    }

    alterarFugaParte(idProcessoCriminal : number, idProcessoParteLegacy : number, fuga : Fuga) : Observable<Fuga[]>{
      return this.httpClient.put<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/fugas/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy, fuga).pipe(catchError(this._serverError));
    }

    deleteFugaParte(idProcessoCriminal : number, idProcessoParteLegacy : number, fuga : Fuga) : Observable<any>{
        return this.httpClient.post<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/fugas/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy + '/delete', fuga);
    }

    getSolturasParte(idProcessoCriminal : number, idProcessoParteLegacy : number) : Observable<Soltura[]>{
        return this.httpClient.get<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/solturas/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy).pipe(catchError(this._serverError));
    }

    postSolturaParte(idProcessoCriminal : number, idProcessoParteLegacy : number, soltura : Soltura) : Observable<Soltura[]>{
        return this.httpClient.post<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/solturas/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy, soltura).pipe(catchError(this._serverError));
    }

    alterarSolturaParte(idProcessoCriminal : number, idProcessoParteLegacy : number, soltura : Soltura) : Observable<Soltura[]>{
      return this.httpClient.put<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/solturas/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy, soltura).pipe(catchError(this._serverError));
    }

    deleteSolturaParte(idProcessoCriminal : number, idProcessoParteLegacy : number, soltura : Soltura) : Observable<any>{
        return this.httpClient.post<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/solturas/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy + '/delete', soltura);
    }

    getProcessoCriminal(numeroProcessoLegacy : string) : Observable<Processo>{
        return this.httpClient.get<Processo>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/processos/numero/' + numeroProcessoLegacy).pipe(catchError(this._serverError));
    }

    getTipoInformacaoCriminal(codigoMovimento : string) : Observable<TipoInformacaoCriminal[]>{
        return this.httpClient.get<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/tiposInformacaoCriminal/evento/' + codigoMovimento).pipe(catchError(this._serverError));
    }

    salvarInformacaoCriminalParte(idProcesso: number, idProcessoParteLegacy: number, eventoCriminal: EventoCriminal) {
        return this.httpClient.post<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/eventosCriminais/processos/' + idProcesso.toString() + '/partes/' + idProcessoParteLegacy.toString(), eventoCriminal).pipe(catchError(this._serverError));
    }

    alterarInformacaoCriminalParte(idProcesso: number, idProcessoParteLegacy: number, eventoCriminal: EventoCriminal) {
      return this.httpClient.put<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/eventosCriminais/processos/' + idProcesso.toString() + '/partes/' + idProcessoParteLegacy.toString(), eventoCriminal).pipe(catchError(this._serverError));
    }

    getEventosCriminais(idProcesso: number, idProcessoParteLegacy: number) {
        return this.httpClient.get<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/eventosCriminais/processos/' + idProcesso.toString() + '/partes/' + idProcessoParteLegacy.toString()).pipe(catchError(this._serverError));
    }

    getEventosCriminaisDTO(idProcesso: number, idProcessoParteLegacy: number): Observable<EventoCriminalDTO[]> {
        return this.httpClient.get<EventoCriminalDTO[]>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/eventosCriminaisDTO/processos/'
        + idProcesso.toString() + '/partes/' + idProcessoParteLegacy.toString()).pipe(catchError(this._serverError));
    }

    getIncidencias(idProcesso: number, idProcessoParteLegacy: number) {
        return this.httpClient.get<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/processos/' + idProcesso.toString() + '/partes/' + idProcessoParteLegacy.toString() + '/incidencias').pipe(catchError(this._serverError));
    }

  	getPendencias(idProcesso: number, movimentos : any) : Observable<PendenciaCriminal[]> {
    	return this.httpClient.post<PendenciaCriminal[]>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/eventosCriminais/processos/'+ idProcesso.toString() + '/pendencias',movimentos).pipe(catchError(this._serverError));
  	}


    getTiposEventosCriminais(): Observable<TipoEventoCriminal[]> {
        return this.httpClient.get<TipoEventoCriminal[]>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/tiposEventosCriminais').pipe(catchError(this._serverError));
    }

    deleteEventosCriminais(idProcessoCriminal: number, idProcessoParteLegacy: number, eventoCriminal: EventoCriminal): Observable<any> {
        return this.httpClient.request<any>('delete', this.config.getUrlApiGateway() + '/' + CRIMINAL + '/eventosCriminais/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy + '/idEventoCriminal/' + eventoCriminal.id, { body: eventoCriminal });
    }

    getMotivosSuspensaoProcesso() : Observable<MotivoSuspensaoProcesso[]>{
        return this.httpClient.get<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + "/motivosSuspensaoProcesso").pipe(catchError(this._serverError));
    }

    postDecisaoInstanciaSuperiorParte(idProcessoCriminal : number, idProcessoParteLegacy : number, decisaoInstanciaSuperior : DecisaoInstanciaSuperior) : Observable<DecisaoInstanciaSuperior[]>{
        return this.httpClient.post<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/decisoesInstanciaSuperior/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy, decisaoInstanciaSuperior).pipe(catchError(this._serverError));
    }

    alterarDecisaoInstanciaSuperiorParte(idProcessoCriminal : number, idProcessoParteLegacy : number, decisaoInstanciaSuperior : DecisaoInstanciaSuperior) : Observable<DecisaoInstanciaSuperior[]> {
        return this.httpClient.put<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/decisoesInstanciaSuperior/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy, decisaoInstanciaSuperior).pipe(catchError(this._serverError));
    }

    getTiposRecursos() : Observable<TipoRecurso[]>{
        return this.httpClient.get<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + "/tiposRecurso").pipe(catchError(this._serverError));
    }

    getRegimes(): Observable<Regime[]>{
        return this.httpClient.get<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + "/regimes").pipe(catchError(this._serverError));
    }

    postSentencaAbsolutoriaParte(idProcessoCriminal : number, idProcessoParteLegacy : number, sentencaAbsolutoria : SentencaAbsolutoria) : Observable<SentencaAbsolutoria[]>{
        return this.httpClient.post<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/sentencasAbsolutorias/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy, sentencaAbsolutoria).pipe(catchError(this._serverError));
    }

    alterarSentencaAbsolutoriaParte(idProcessoCriminal : number, idProcessoParteLegacy : number, sentencaAbsolutoria : SentencaAbsolutoria) : Observable<SentencaAbsolutoria[]> {
        return this.httpClient.put<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/sentencasAbsolutorias/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy, sentencaAbsolutoria).pipe(catchError(this._serverError));
    }

    postSentencaExtincaoPunibilidadeParte(idProcessoCriminal: number, idProcessoParteLegacy: number,
            sentencaExtincaoPunibilidade: SentencaExtincaoPunibilidade): Observable<SentencaExtincaoPunibilidade[]> {
        return this.httpClient.post<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL
            + '/sentencasExtincaoPunibilidade/processos/' + idProcessoCriminal
            + '/partes/' + idProcessoParteLegacy, sentencaExtincaoPunibilidade).pipe(catchError(this._serverError));
    }

    alterarSentencaExtincaoPunibilidadeParte(idProcessoCriminal: number, idProcessoParteLegacy: number,
        sentencaExtincaoPunibilidade: SentencaExtincaoPunibilidade): Observable<SentencaExtincaoPunibilidade[]> {
        return this.httpClient.put<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL
            + '/sentencasExtincaoPunibilidade/processos/' + idProcessoCriminal
            + '/partes/' + idProcessoParteLegacy, sentencaExtincaoPunibilidade).pipe(catchError(this._serverError));
    }

    postCitacaoParte(idProcessoCriminal: number,
                     idProcessoParteLegacy: number,
                     citacao: Citacao): Observable<Citacao[]> {
        return this.httpClient.post<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL
        + '/citacoes/processos/' + idProcessoCriminal + '/partes/'
        + idProcessoParteLegacy, citacao).pipe(catchError(this._serverError));
    }

    alterarCitacaoParte(idProcessoCriminal: number,
                        idProcessoParteLegacy: number,
                        citacao: Citacao): Observable<Citacao[]> {
        return this.httpClient.put<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL
        + '/citacoes/processos/' + idProcessoCriminal + '/partes/'
        + idProcessoParteLegacy, citacao).pipe(catchError(this._serverError));
    }

    postRecebimentoAditamentoDenunciaParte(
        idProcessoCriminal: number,
        idProcessoParteLegacy: number,
        recebimentoAditamentoDenuncia: RecebimentoAditamentoDenuncia): Observable<RecebimentoAditamentoDenuncia[]> {
        return this.httpClient.post<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL
            + '/recebimentosAditamentoDenuncia/processos/' + idProcessoCriminal + '/partes/'
            + idProcessoParteLegacy, recebimentoAditamentoDenuncia).pipe(catchError(this._serverError));
    }

    alterarRecebimentoAditamentoDenunciaParte(
        idProcessoCriminal: number,
        idProcessoParteLegacy: number,
        recebimentoAditamentoDenuncia: RecebimentoAditamentoDenuncia): Observable<RecebimentoAditamentoDenuncia[]> {
        return this.httpClient.put<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL
            + '/recebimentosAditamentoDenuncia/processos/' + idProcessoCriminal + '/partes/'
            + idProcessoParteLegacy, recebimentoAditamentoDenuncia).pipe(catchError(this._serverError));
    }

    postSentencaImpronuncia(idProcessoCriminal: number,
                            idProcessoParteLegacy: number,
                            sentencaImpronuncia: SentencaImpronuncia): Observable<SentencaImpronuncia[]> {
        return this.httpClient.post<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL
            + '/sentencasImpronuncia/processos/' + idProcessoCriminal + '/partes/'
            + idProcessoParteLegacy, sentencaImpronuncia).pipe(catchError(this._serverError));
    }

    alterarSentencaImpronuncia(idProcessoCriminal: number,
                               idProcessoParteLegacy: number,
                               sentencaImpronuncia: SentencaImpronuncia): Observable<SentencaImpronuncia[]> {
        return this.httpClient.put<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL
            + '/sentencasImpronuncia/processos/' + idProcessoCriminal + '/partes/'
            + idProcessoParteLegacy, sentencaImpronuncia).pipe(catchError(this._serverError));
    }

    postSentencaPronuncia(idProcessoCriminal: number,
                          idProcessoParteLegacy: number,
                          sentencaPronuncia: SentencaPronuncia): Observable<SentencaPronuncia[]> {
        return this.httpClient.post<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL
            + '/sentencasPronuncia/processos/' + idProcessoCriminal + '/partes/'
            + idProcessoParteLegacy, sentencaPronuncia).pipe(catchError(this._serverError));
    }

    alterarSentencaPronuncia(idProcessoCriminal: number,
                             idProcessoParteLegacy: number,
                             sentencaPronuncia: SentencaPronuncia): Observable<SentencaPronuncia[]> {
        return this.httpClient.put<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL
            + '/sentencasPronuncia/processos/' + idProcessoCriminal + '/partes/'
            + idProcessoParteLegacy, sentencaPronuncia).pipe(catchError(this._serverError));
    }

    postDesclassificacaoProcesso(idProcessoCriminal: number,
                                 idProcessoParteLegacy: number,
                                 desclassificacaoProcesso: DesclassificacaoProcesso): Observable<DesclassificacaoProcesso[]> {
        return this.httpClient.post<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL
            + '/desclassificacoesProcesso/processos/' + idProcessoCriminal + '/partes/'
            + idProcessoParteLegacy, desclassificacaoProcesso).pipe(catchError(this._serverError));
    }

    alterarDesclassificacaoProcesso(idProcessoCriminal: number,
                                    idProcessoParteLegacy: number,
                                    desclassificacaoProcesso: DesclassificacaoProcesso): Observable<DesclassificacaoProcesso[]> {
        return this.httpClient.put<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL
            + '/desclassificacoesProcesso/processos/' + idProcessoCriminal + '/partes/'
            + idProcessoParteLegacy, desclassificacaoProcesso).pipe(catchError(this._serverError));
    }
}
